import { useCallback } from 'react';

interface UseToggleBooleanUrlParamProps {
  paramName: string;
  value?: string;
  onToggle?: () => void;
}

export const useToggleBooleanUrlParam = ({
  paramName,
  value = 'true',
  onToggle,
}: UseToggleBooleanUrlParamProps) => {
  return useCallback(
    (currentState: boolean) => {
      const url = new URL(window.location.href);

      if (!currentState) {
        url.searchParams.set(paramName, value);
      } else {
        url.searchParams.delete(paramName);
      }

      window.history.pushState({}, '', url);
      onToggle?.();
    },
    [paramName, value, onToggle],
  );
};
