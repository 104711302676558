import { QuerySortParamOptions } from '@/api/types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

type Filters<T> = {
  [K in keyof T]?: any;
};

export const useTablePagination = <T extends object>(defaultSort?: string) => {
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: Number(params.get('pageIndex')),
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const [dir, setDir] = useState<QuerySortParamOptions>(DEFAULT_PAGINATION_DIR);

  const [filters, setFilters] = useState<Filters<T>>({});

  const [sort, setSort] = useState<keyof T>((defaultSort ?? DEFAULT_PAGINATION_SORT) as keyof T);

  const [showInactive, setShowInactive] = useState<boolean>(params.get('showInactive') === 'true');

  const [selectedDate, setSelectedDate] = useState<string>(params.get('date') || '');

  const [startDate, setStartDate] = useState<string>(params.get('startDate') || '');
  const [endDate, setEndDate] = useState<string>(params.get('endDate') || '');

  const paginationState = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  return {
    pageIndex,
    pageSize,
    paginationState,
    sort,
    dir,
    filters,
    setPagination,
    setDir,
    setSort,
    setFilters,
    selectedDate,
    setSelectedDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    showInactive,
    setShowInactive,
  };
};
