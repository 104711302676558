import { FC } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import { Button } from '../ui/button';

interface TableRowActionsProps {
  onEdit?: () => void;
  onDelete?: () => void;
  disabledEdit?: boolean;
  disabledDelete?: boolean;
  onOpen?: () => void;
  onEnable?: () => void;
  onDisable?: () => void;
}

const TableRowActions: FC<TableRowActionsProps> = ({
  onEdit,
  onDelete,
  disabledEdit,
  onOpen,
  disabledDelete,
  onEnable,
  onDisable,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="w-8 h-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {onEdit && !disabledEdit && (
          <DropdownMenuItem className="cursor-pointer" onClick={onEdit}>
            Editar
          </DropdownMenuItem>
        )}
        {onOpen && (
          <DropdownMenuItem className="cursor-pointer" onClick={onOpen}>
            Abrir
          </DropdownMenuItem>
        )}
        {onEnable && (
          <DropdownMenuItem
            className="cursor-pointer text-primary hover:text-primary"
            onClick={onEnable}
          >
            Activar
          </DropdownMenuItem>
        )}
        {onDisable && (
          <DropdownMenuItem
            className="cursor-pointer text-amber-500 hover:text-amber-500"
            onClick={onDisable}
          >
            Desactivar
          </DropdownMenuItem>
        )}
        {onDelete && !disabledDelete && (
          <DropdownMenuItem
            className="cursor-pointer hover:bg-destructive hover:text-white focus:bg-destructive focus:text-white text-destructive"
            onClick={onDelete}
          >
            Eliminar
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { TableRowActions };
