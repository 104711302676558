import { ColumnDef } from '@tanstack/react-table';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { cn, formatAmount, hasOtherParams, handleClearQueryParams } from '@/lib/utils.ts';
import { Badge } from '@/components/ui/badge.tsx';
import { TableRowActions } from '@/components/shared/TableRowActions';
import { CategoryApi } from '@/api/category/category.model';
import { OPACITY_ENABLED, OPACITY_DISABLED } from '@/common/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';

const CategoriesTableColumns = <Category extends CategoryApi>(
  onEdit?: (item: Category) => void,
  onDelete?: (item: Category) => void,
  onEnable?: (item: Category) => void,
  onDisable?: (item: Category) => void,
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const categoriesTableColumns: ColumnDef<Category>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => <SortColumnWithBe title="Nombre" column={column} />,
      cell: ({ row }) => {
        const enabled = row.original.enabled;
        const name = row.original.name;
        return enabled ? (
          <p className="text-sm">{name}</p>
        ) : (
          <div className="flex flex-col items-start gap-1">
            <Badge
              variant="outlineRed"
              className="px-2 py-[0px] text-[10px] font-normal rounded-xl opacity-70"
            >
              Inactiva
            </Badge>
            <p className={cn('text-sm', OPACITY_DISABLED)}>{name}</p>
          </div>
        );
      },
    },
    {
      accessorKey: 'total',
      header: () => <p className="text-xs">Total</p>,
      cell: ({ row }) => {
        const enabled = row.original.enabled;
        const amount = formatAmount(row.original.total);
        return (
          <p
            className={cn(
              Number.parseFloat(amount) < 0 ? 'text-rose-700' : 'text-green-700',
              enabled ? OPACITY_ENABLED : OPACITY_DISABLED,
            )}
          >
            {amount}
          </p>
        );
      },
    },
    {
      accessorKey: 'expenses',
      header: () => <p className="text-xs">Gastos</p>,
      cell: ({ row }) => {
        const enabled = row.original.enabled;
        const amount = formatAmount(row.original.expenses);
        return (
          <p className={cn('text-rose-700', enabled ? OPACITY_ENABLED : OPACITY_DISABLED)}>
            {amount}
          </p>
        );
      },
    },
    {
      accessorKey: 'balances',
      header: () => <p className="text-xs">Balance</p>,
      cell: ({ row }) => {
        const enabled = row.original.enabled;
        const amount = formatAmount(row.original.balance);
        return (
          <p className={cn('text-green-700', enabled ? OPACITY_ENABLED : OPACITY_DISABLED)}>
            {amount}
          </p>
        );
      },
    },
    {
      accessorKey: 'actions',
      header: () => {
        return (
          <div className="flex items-center">
            {location.search && hasOtherParams(location.search) ? (
              <Button
                type="button"
                variant="link"
                className="absolute right-[35px] w-fit h-fit p-0 text-destructive"
                onClick={() => handleClearQueryParams(navigate)}
              >
                <Trash2 className="w-3 h-3 text-s color-destructive" />
              </Button>
            ) : null}
          </div>
        );
      },
      cell: ({ row }) => {
        const item = row.original;
        const enabled = row.original.enabled;
        return (
          <div className="flex h-8 justify-end">
            {enabled ? (
              <TableRowActions
                onEdit={() => (onEdit ? onEdit(item) : undefined)}
                onDelete={() => (onDelete ? onDelete(item) : undefined)}
                onDisable={() => (onDisable ? onDisable(item) : undefined)}
              />
            ) : (
              <TableRowActions onEnable={() => (onEnable ? onEnable(item) : undefined)} />
            )}
          </div>
        );
      },
    },
  ];
  return categoriesTableColumns;
};

export { CategoriesTableColumns };
