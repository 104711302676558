import { DonationApi, DonationState, donationStateMap } from '@/api/donation/donation.model';
import { ColumnDef } from '@tanstack/react-table';
import { CostCenterApi } from '@/api/costCenter/costCenter.model';
import {
  cn,
  formatAmount,
  genericColumnFilterFn,
  getDataArray,
  getParamsWithAnimation,
  handleClearQueryParams,
  hasOtherParams,
} from '@/lib/utils';
import { SortColumnWithBe } from '@/components/shared/SortColumnWithBe';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { pathsBase } from '@/routes/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCostCenterCellFiltered } from '@/pages/CostCenter/utils/CostCenterUtils';
import { FilterColumnWithBe } from '@/components/shared/FilterColumnWithBe';
import { useCostCentersList } from '@/api/costCenter/costCenter.api';
import { TableRowActions } from '@/components/shared/TableRowActions';
import { OPACITY_ENABLED, OPACITY_DISABLED } from '@/common/constants';
import { Trash2 } from 'lucide-react';
import { useMemo } from 'react';

const DonationTableColumns = (
  onDetail: (item: DonationApi) => void,
  onDelete: (item: DonationApi) => void,
  onEnable: (item: DonationApi) => void,
  onDisable: (item: DonationApi) => void,
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onPayDonationClick = (id: string) => {
    navigate(
      `/${pathsBase.DONATIONS}/${id}/${pathsBase.PAY}${getParamsWithAnimation(location.search)}`,
    );
  };

  const donationsTableColumns: ColumnDef<DonationApi>[] = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => <SortColumnWithBe title="Nombre" column={column} />,
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const name = row.getValue('name') as string;
          return enabled ? (
            <div className="line-clamp-2">
              <p className={OPACITY_ENABLED}>{name}</p>
            </div>
          ) : (
            <div className="flex flex-col items-start gap-1">
              <Badge
                variant="outlineRed"
                className="px-2 py-[0px] text-[10px] font-normal rounded-xl opacity-70"
              >
                Inactiva
              </Badge>
              <div className="line-clamp-2">
                <p className={OPACITY_DISABLED}>{name}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'reportDate',
        header: ({ column }) => <SortColumnWithBe title="Vencimiento" column={column} />,
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const reportDate = row.getValue('reportDate') as string;
          return <p className={enabled ? OPACITY_ENABLED : OPACITY_DISABLED}>{reportDate}</p>;
        },
      },
      {
        accessorKey: 'costCenters',
        filterFn: genericColumnFilterFn<DonationApi>('costCenters', 'id'),
        header: ({ column }) => {
          return (
            <FilterColumnWithBe
              title="CC Asociados"
              column={column}
              filterOptions={getDataArray(useCostCentersList)}
              commandDropdownClassname="w-[13.5rem] max-w-[13.5rem]"
            />
          );
        },
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const costCenters = row.getValue('costCenters') as Array<CostCenterApi>;

          if (costCenters && costCenters.length > 0) {
            const cell = getCostCenterCellFiltered(costCenters);

            return <span className={enabled ? OPACITY_ENABLED : OPACITY_DISABLED}>{cell}</span>;
          }

          return <span className={enabled ? OPACITY_ENABLED : OPACITY_DISABLED}>-</span>;
        },
      },
      {
        accessorKey: 'amount',
        header: ({ column }) => <SortColumnWithBe title="Donación" column={column} />,
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const amount = parseFloat(row.getValue('amount'));
          return (
            <p className={enabled ? OPACITY_ENABLED : OPACITY_DISABLED}>{formatAmount(amount)}</p>
          );
        },
      },
      {
        accessorKey: 'totalExpenses',
        header: () => <p className="text-xs">Gastos Asociados</p>,
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const amount = parseFloat(row.getValue('totalExpenses'));
          return (
            <p className={enabled ? OPACITY_ENABLED : OPACITY_DISABLED}>{formatAmount(amount)}</p>
          );
        },
      },
      {
        accessorKey: 'balance',
        header: () => <p className="text-xs">Balance</p>,
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const amount = parseFloat(row.getValue('balance'));
          return (
            <p
              className={cn(
                amount < 0 ? 'text-rose-700' : 'text-green-700',
                enabled ? OPACITY_ENABLED : OPACITY_DISABLED,
              )}
            >
              {formatAmount(amount)}
            </p>
          );
        },
      },
      {
        accessorKey: 'id',
        header: 'Acciones',
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const state = row.original.state as DonationApi['state'];
          return (
            <div className={enabled ? OPACITY_ENABLED : `${OPACITY_DISABLED} pointer-events-none`}>
              {state === 'PENDING' && (
                <Button
                  variant="link"
                  className="h-fit p-0"
                  onClick={() => {
                    onPayDonationClick(row.getValue('id'));
                  }}
                >
                  <span className="text-xs text-foreground font-medium underline transition-colors hover:text-blue-500">
                    Registrar Cobro
                  </span>
                </Button>
              )}
              {state === DonationState.COMPLETED && (
                <span className="text-xs text-teal-700 text-muted-foreground font-medium">
                  Cobrado
                </span>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'state',
        header: ({ column }) => {
          const resultArray = (Object.keys(donationStateMap) as Array<DonationState>).map(
            (key) => ({
              label: donationStateMap[key],
              value: key,
            }),
          );
          return <FilterColumnWithBe title="Estado" column={column} filterOptions={resultArray} />;
        },
        cell: ({ row }) => {
          const enabled = row.original.enabled;
          const state = row.getValue('state') as DonationApi['state'];
          const stateLabel = donationStateMap[state as keyof typeof donationStateMap] || '-';
          return (
            <Badge
              className={enabled ? OPACITY_ENABLED : OPACITY_DISABLED}
              variant={
                state
                  ? state === DonationState.COMPLETED
                    ? 'outlineGreen'
                    : 'outlineYellow'
                  : 'outlineGray'
              }
            >
              {stateLabel}
            </Badge>
          );
        },
        maxSize: 100,
      },
      {
        accessorKey: 'actions',
        header: () => {
          return (
            <div className="flex items-center justify-end w-full relative">
              {location.search && hasOtherParams(location.search) && (
                <Button
                  type="button"
                  variant="link"
                  className="w-fit h-fit p-0 text-destructive"
                  onClick={() => handleClearQueryParams(navigate)}
                >
                  <Trash2 className="w-3 h-3" />
                </Button>
              )}
            </div>
          );
        },
        cell: ({ row }) => {
          const item = row.original;
          const enabled = row.original.enabled;
          return (
            <div className="flex h-8 justify-end">
              {enabled ? (
                <TableRowActions
                  onOpen={() => onDetail(item)}
                  onDelete={() => onDelete(item)}
                  onDisable={() => onDisable(item)}
                />
              ) : (
                <TableRowActions onEnable={() => onEnable(item)} />
              )}
            </div>
          );
        },
      },
    ],
    [location.search, navigate],
  );

  return donationsTableColumns;
};

export { DonationTableColumns };
