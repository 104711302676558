import { CategoryFormType } from '@/pages/Categories/utils/CategorySchema';
import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryRequestParams, ResponsePaginated } from '../types';
import {
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SHOW_INACTIVE,
  DEFAULT_PAGINATION_SORT,
} from '@/common/constants';
import { CategoryApi, CategoryListItem, CategoriesTransformResponse } from './category.model';
import { CRUDService } from '../CRUDService.api';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';

const CATEGORIES_BASE_PATH = '/category';
const CATEGORIES_KEY = 'category';
const CATEGORIES_STALE_TIME = 5000;

const transformCategoriesList = (response: Array<CategoryApi>): Array<CategoryListItem> => {
  return response.map((category) => {
    return {
      id: category.id,
      name: category.name,
      value: category.id,
      balance: category.balance,
    };
  });
};

class CategoryService extends CRUDService<CategoryApi, CategoryFormType> {
  constructor() {
    super(CATEGORIES_BASE_PATH);
  }

  async getList() {
    try {
      const response = await httpClient.get(`${CATEGORIES_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getCategoriesByDonationCostCenter(donationId: string, costCenterId: string) {
    try {
      const response = await httpClient.get(
        `${CATEGORIES_BASE_PATH}/costCenter/${costCenterId}/donation/${donationId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const categoryService = new CategoryService();

const useCategories = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  showInactive = DEFAULT_PAGINATION_SHOW_INACTIVE,
}: QueryRequestParams<CategoryApi> = {}) => {
  const enabled = showInactive ? '' : 'enabled=true';
  const queryParams = `page=${page}&size=${size}&sort=${sort}&dir=${dir}&${enabled}`;

  return useQuery<ResponsePaginated<CategoryApi>, Error, CategoriesTransformResponse>({
    queryKey: [CATEGORIES_KEY, { page, size, showInactive }],
    queryFn: async () => {
      const response = await categoryService.getAll(queryParams);
      return response as ResponsePaginated<CategoryApi>;
    },
    placeholderData: keepPreviousData,
    staleTime: CATEGORIES_STALE_TIME,
  });
};

const useCategory = (id: UUID) => {
  return useQuery<CategoryApi, Error, CategoryApi>({
    queryKey: [`${CATEGORIES_KEY}-edit`, { id }],
    queryFn: async () => {
      const response = await categoryService.get(id);
      return response as CategoryApi;
    },
    placeholderData: keepPreviousData,
    staleTime: CATEGORIES_STALE_TIME,
    retry: 1,
  });
};

const useCategoriesList = () => {
  return useQuery<Array<CategoryApi>, Error, Array<CategoryListItem>>({
    queryKey: [`${CATEGORIES_KEY}-list`],
    queryFn: async () => {
      const response = await categoryService.getList();
      return response as Array<CategoryApi>;
    },
    select: transformCategoriesList,
    placeholderData: keepPreviousData,
    staleTime: CATEGORIES_STALE_TIME,
  });
};

const useCategoriesByDonationCostCenter = (donationId: string, costCenterId: string) => {
  return useQuery<Array<CategoryApi>, Error, Array<CategoryListItem>>({
    queryKey: [`${CATEGORIES_KEY}-donation-cost-center`, { donationId, costCenterId }],
    queryFn: async () => {
      const response = await categoryService.getCategoriesByDonationCostCenter(
        donationId,
        costCenterId,
      );
      return response as Array<CategoryApi>;
    },
    select: transformCategoriesList,
    placeholderData: keepPreviousData,
    staleTime: CATEGORIES_STALE_TIME,
    enabled: !!donationId && !!costCenterId,
  });
};

const createCategoryMutationFn = async (data: CategoryFormType) => {
  return await categoryService.create(data);
};

const editCategoryMutationFn: MutationFunction<
  CategoryApi,
  [CategoryApi['id'], CategoryFormType]
> = async (params) => {
  const [id, data] = params;
  return (await categoryService.edit(id, data)) as CategoryApi;
};

const deleteCategoryMutationFn = async (id: CategoryApi['id']) => {
  return await categoryService.delete(id);
};

export {
  CATEGORIES_KEY,
  useCategories,
  useCategory,
  useCategoriesList,
  useCategoriesByDonationCostCenter,
  createCategoryMutationFn,
  editCategoryMutationFn,
  deleteCategoryMutationFn,
};
