import { Helmet } from 'react-helmet';
import { Button } from '@/components/ui/button';
import { CategoriesTableColumns } from './utils/CategoryTableColumns';
import { Heading } from '@/components/shared/Heading';
import { PageContent } from '@/components/shared/PageContent';
import { Outlet, useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { FilePlusIcon } from 'lucide-react';
import {
  deleteCategoryMutationFn,
  editCategoryMutationFn,
  useCategories,
} from '@/api/category/category.api';
import { paths, pathsBase } from '@/routes/paths';
import {
  DEFAULT_PAGINATION_PAGE_COUNT,
  SONNER_ERROR_OPTIONS,
  SONNER_SUCCESS_OPTIONS,
} from '@/common/constants';
import { useTablePagination } from '@/hooks/useTablePagination';
import { AlertDialogWrapper } from '@/components/shared/AlertDialog';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import SuccessToastText from '@/components/shared/SuccessToastText';
import { Category, CategoryApi } from '@/api/category/category.model';
import { TableWrapper } from '@/components/shared/TableWrapper';
import * as Sentry from '@sentry/react';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import useFilterAndSortByBe from '@/hooks/useFilterAndSortByBe';
import { getParamsWithAnimation } from '@/lib/utils';
import { useToggleBooleanUrlParam } from '@/hooks/useToggleBooleanUrlParam';

const PAGE_TITLE = 'Categorías';

const Categories: FC = () => {
  const navigate = useNavigate();

  const {
    pageIndex,
    pageSize,
    paginationState,
    dir,
    sort,
    showInactive,
    setPagination,
    setDir,
    setSort,
    setShowInactive,
  } = useTablePagination<CategoryApi>();

  const { data, isLoading, isFetching, refetch } = useCategories({
    page: pageIndex,
    size: pageSize,
    dir: dir,
    sort: sort,
    showInactive: showInactive,
  });

  const { alertDialog, setAlertDialog, handleDismissAlertDialog } = useAlertDialog();

  const { mutate: deleteCategory, isPending: isDeleteCategoryPending } = useMutation({
    mutationFn: deleteCategoryMutationFn,
    onSuccess: () => {
      refetch();
      toast(
        <SuccessToastText>Categoría eliminada exitosamente</SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la eliminación de la Categoría.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  const { mutate: editStateCategory, isPending: isEditStateCategoryPending } = useMutation({
    mutationFn: editCategoryMutationFn,
    onSuccess: (data: CategoryApi) => {
      refetch();
      toast(
        <SuccessToastText>
          Categoría <span className="text-blue-700">{data.name}</span> fue{' '}
          {data.enabled ? 'activada' : 'desactivada'} exitosamente!
        </SuccessToastText>,
        SONNER_SUCCESS_OPTIONS,
      );
      handleDismissAlertDialog();
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);
      toast.error('Ha ocurrido un error durante la edición de la Categoría.', {
        description: 'Por favor, intentalo nuevamente.',
        ...SONNER_ERROR_OPTIONS,
      });
    },
  });

  useFilterAndSortByBe<CategoryApi>(refetch, setDir, setSort);

  const toggleShowInactive = useToggleBooleanUrlParam({
    paramName: 'showInactive',
    onToggle: () => refetch(),
  });

  const handleCreateCategory = () => navigate(`/${pathsBase.CATEGORIES}/${pathsBase.CREATE}`);

  const handleEditCategory = (category: Category) =>
    navigate(
      `${paths.CATEGORIES}/${pathsBase.EDIT}/${category.id}${getParamsWithAnimation(location.search)}`,
    );

  const handleEnableCategory = (category: Category) => {
    setAlertDialog({
      title: `¿Estás seguro que querés activar "${category.name}"? `,
      description: `Esta acción volverá a activar la categoría "${category.name}" y permitirá registrar gastos en ella.`,
      open: true,
      mainButtonVariant: 'primary',
      onCancel: handleDismissAlertDialog,
      onConfirm: () => editStateCategory([category.id, { enabled: true, name: category.name }]),
    });
  };

  const handleDisableCategory = (category: Category) => {
    setAlertDialog({
      title: `¿Estás seguro que querés desactivar "${category.name}"? `,
      description: `Esta acción desactivará la categoría "${category.name}" y no permitirá registrar gastos en ella.`,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => editStateCategory([category.id, { enabled: false, name: category.name }]),
    });
  };

  const handleDeleteCategory = (category: Category) => {
    setAlertDialog({
      title: `¿Estás seguro que querés eliminar "${category.name}"? `,
      description: `Esta acción no puede ser deshecha y se perderá toda la información asociada a esta Categoría: "${category.name}". `,
      open: true,
      onCancel: handleDismissAlertDialog,
      onConfirm: () => deleteCategory(category.id),
    });
  };

  const handleShowInactiveCategories = () => {
    setShowInactive(!showInactive);
    toggleShowInactive(showInactive);
  };

  const columns = CategoriesTableColumns<Category>(
    handleEditCategory,
    handleDeleteCategory,
    handleEnableCategory,
    handleDisableCategory,
  );

  return (
    <>
      <Helmet>
        <title>PEM - {PAGE_TITLE}</title>
      </Helmet>
      <Heading
        title={PAGE_TITLE}
        rightComponent={
          <Button onClick={handleCreateCategory}>
            <FilePlusIcon className="w-4 h-4 mr-2" />
            Crear Categoría
          </Button>
        }
      />
      <PageContent>
        <TableWrapper
          columns={columns}
          data={data}
          isLoading={isLoading || isEditStateCategoryPending}
          isFetching={isFetching}
          rowsLabel={PAGE_TITLE}
          pageCount={data ? data.totalPages : DEFAULT_PAGINATION_PAGE_COUNT}
          pagination={paginationState}
          onPaginationChange={setPagination}
          leftComponent={
            <Button
              variant="outlinePrimary"
              size="sm"
              onClick={handleShowInactiveCategories}
              disabled={isFetching || isLoading}
            >
              {showInactive ? 'Ocultar Categorías Inactivas' : 'Ver Categorías Inactivas'}
            </Button>
          }
        />
      </PageContent>
      <AlertDialogWrapper
        {...alertDialog}
        isLoading={isDeleteCategoryPending || isEditStateCategoryPending}
        onDismiss={handleDismissAlertDialog}
      />
      <Outlet />
    </>
  );
};

export default Categories;
