import { MutationFunction, keepPreviousData, useQuery } from '@tanstack/react-query';
import { DonationQueryParams, ResponsePaginated } from '../types';
import {
  DEFAULT_COST_CENTER_OPTION,
  DEFAULT_PAGINATION_DIR,
  DEFAULT_PAGINATION_PAGE_INDEX,
  DEFAULT_PAGINATION_PAGE_SEARCH,
  DEFAULT_PAGINATION_PAGE_SIZE,
  DEFAULT_PAGINATION_SHOW_INACTIVE,
  DEFAULT_PAGINATION_SORT,
  DEFAULT_STATE_OPTION,
} from '@/common/constants';
import { CRUDService } from '../CRUDService.api';
import {
  DonationApi,
  DonationCreatePayload,
  DonationDetailsPayload,
  DonationList,
} from './donation.model';
import { DonationFormType } from '@/pages/Donations/utils/DonationSchema';
import { getConvertedAmount } from '@/lib/utils';
import httpClient from '../httpClient';
import * as Sentry from '@sentry/react';
import { UUID } from 'crypto';
import { DonationPayFormType } from '@/pages/Donations/utils/DonationPaySchema';
import { createAccountingEntriesMutationFn } from '../accountingEntry/accountingEntry.api';

const DONATION_BASE_PATH = '/donation';
const DONATION_KEY = 'donations';
const DONATION_STALE_TIME = 5000;

const transformDonationPayload = (data: DonationFormType): DonationCreatePayload => {
  const donationDetails: Array<DonationDetailsPayload> = [
    {
      type: 'DonationDetail',
      amount: getConvertedAmount(data.globalCostCenter.amount, data.exchangeRate),
      destination: {
        costCenter: { id: data.globalCostCenter.id },
      },
      money: {
        currency: data.currency,
        amount: data.globalCostCenter.amount,
        exchangeRate: data.exchangeRate,
      },
    },
  ];
  data.costCenters.forEach((costCenter) => {
    costCenter.categories.forEach((category) => {
      donationDetails.push({
        type: 'DonationDetail',
        amount: getConvertedAmount(category.amount, data.exchangeRate),
        destination: {
          category: { id: category.id },
          costCenter: { id: costCenter.id },
        },
        money: {
          currency: data.currency,
          amount: category.amount,
          exchangeRate: data.exchangeRate,
        },
      });
    });
  });

  return {
    fiscalSponsor: { id: data.fiscalSponsor.id },
    donor: { id: data.donor.id },
    amount: getConvertedAmount(data.amount ?? 0, data.exchangeRate),
    year: Number(data.year),
    name: data.name,
    description: data.description,
    reportDate: data.reportDate.toISOString().split('T')[0],
    entryDate: data.entryDate.toISOString().split('T')[0],
    donationDetails: donationDetails,
    files: data.files,
    money: {
      currency: data.currency,
      amount: data.amount,
      exchangeRate: data.exchangeRate,
    },
  };
};

const tranformDonationToDonationList = (response: Array<DonationApi>): Array<DonationList> => {
  return response.map((donation) => {
    return {
      id: donation.id,
      name: donation.name,
      value: donation.name,
    };
  });
};

class DonationService extends CRUDService<DonationApi, DonationCreatePayload> {
  constructor() {
    super(DONATION_BASE_PATH);
  }

  async getDonationsByCostCenter(costCenterId: string, categoryId: string) {
    try {
      const response = await httpClient.get(
        `${DONATION_BASE_PATH}/category/${categoryId}/costCenter/${costCenterId}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getList() {
    try {
      const response = await httpClient.get(`${DONATION_BASE_PATH}/list`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async toggleEnabled(id: string) {
    try {
      const response = await httpClient.patch(`${DONATION_BASE_PATH}/toggle-enabled/${id}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

const donationService = new DonationService();

const useDonations = ({
  page = DEFAULT_PAGINATION_PAGE_INDEX,
  size = DEFAULT_PAGINATION_PAGE_SIZE,
  sort = DEFAULT_PAGINATION_SORT,
  dir = DEFAULT_PAGINATION_DIR,
  search = DEFAULT_PAGINATION_PAGE_SEARCH,
  costCenters = DEFAULT_COST_CENTER_OPTION,
  state = DEFAULT_STATE_OPTION,
  showInactive = DEFAULT_PAGINATION_SHOW_INACTIVE,
}: DonationQueryParams<DonationApi> = {}) => {
  const enabled = showInactive ? '' : 'enabled=true';
  const queryParams =
    `page=${page}&size=${size}&sort=${sort}&dir=${dir}&search=${search}&${enabled}` +
    (costCenters ? `&costCenters=${costCenters}` : '') +
    (state ? `&state=${state}` : '');

  return useQuery<
    [ResponsePaginated<DonationApi>, { totalDonations: number }],
    Error,
    [ResponsePaginated<DonationApi>, { totalDonations: number }]
  >({
    queryKey: [DONATION_KEY, { page, size, search, sort, dir, costCenters, state, showInactive }],
    queryFn: async () => {
      const response = (await donationService.getAll(queryParams)) as {
        page: ResponsePaginated<DonationApi>;
        data: { totalDonations: number };
      };
      return [response.page, response.data];
    },
    placeholderData: keepPreviousData,
    staleTime: DONATION_STALE_TIME,
  });
};

const useDonation = (id: DonationApi['id']) => {
  return useQuery<DonationApi, Error>({
    queryKey: [DONATION_KEY, { id }],
    queryFn: async () => {
      const response = await donationService.get(id as UUID);
      return response as DonationApi;
    },
    placeholderData: keepPreviousData,
    staleTime: DONATION_STALE_TIME,
    enabled: !!id,
  });
};

const useDonationsList = () => {
  return useQuery<Array<DonationApi>, Error, Array<DonationList>>({
    queryKey: [`${DONATION_KEY}-list`],
    queryFn: async () => {
      const response = await donationService.getList();
      return response as Array<DonationApi>;
    },
    select: tranformDonationToDonationList,
    placeholderData: keepPreviousData,
    staleTime: DONATION_STALE_TIME,
  });
};

const useDonationsByCostCenter = (costCenterId: string, categoryId: string) => {
  return useQuery<Array<DonationApi>, Error, Array<DonationList>>({
    queryKey: [`${DONATION_KEY}-costCenter`, { costCenterId }],
    queryFn: async () => {
      const response = await donationService.getDonationsByCostCenter(costCenterId, categoryId);
      return response as Array<DonationApi>;
    },
    select: tranformDonationToDonationList,
    placeholderData: keepPreviousData,
    staleTime: DONATION_STALE_TIME,
    enabled: !!costCenterId,
  });
};

const createDonationMutationFn = async (data: DonationFormType) => {
  const payload = transformDonationPayload(data);
  return await donationService.create(payload);
};

const toggleEnabledMutationFn: MutationFunction<DonationApi, string> = async (id) => {
  return (await donationService.toggleEnabled(id)) as DonationApi;
};

const editDonationMutationFn: MutationFunction<
  DonationApi,
  [DonationApi['id'], DonationFormType]
> = async (params) => {
  const [id, data] = params;
  const payload = transformDonationPayload(data);
  return (await donationService.edit(id, payload)) as DonationApi;
};

const deleteDonationMutationFn = async (id: DonationApi['id']) => {
  return await donationService.delete(id);
};

const payDonationMutationFn = async (data: DonationPayFormType) => {
  return await createAccountingEntriesMutationFn(data);
};

export {
  DONATION_KEY,
  useDonations,
  useDonation,
  useDonationsList,
  useDonationsByCostCenter,
  createDonationMutationFn,
  editDonationMutationFn,
  deleteDonationMutationFn,
  payDonationMutationFn,
  toggleEnabledMutationFn,
};
