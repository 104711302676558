import { UUID } from 'crypto';
import httpClient from './httpClient';
import * as Sentry from '@sentry/react';

export class CRUDService<T, TForm> {
  constructor(private basePath: string) {}

  async getAll(params?: string) {
    try {
      const response = await httpClient.get(
        params ? `${this.basePath}/all?${params}` : `${this.basePath}/all`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async get(searchParam?: UUID) {
    try {
      const response = await httpClient.get(`${this.basePath}/${searchParam}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async patch(searchParam?: UUID) {
    try {
      const response = await httpClient.patch(`${this.basePath}/${searchParam}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async create(data: TForm) {
    try {
      const response = await httpClient.post<T>(this.basePath, { data });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async edit(id: string | number, data: TForm) {
    try {
      const response = await httpClient.put(`${this.basePath}/${id}`, { data });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async delete(id: string | number) {
    try {
      const response = await httpClient.delete(`${this.basePath}/${id}`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}
